<template>
  <div id="HomePage">
    <Navbar />
    <RunningText />

    <div id="ResultContent">
      <div id="ResultHead">
        <h1>{{ this.chosenLang === 'bn' ? `宝くじの結果` : `Lottery Results` }}</h1>

        <div id="smallBtnWrap">
          <div class="smallBtn" @click="prev">{{ this.chosenLang === 'bn' ? `前` : `Prev` }}</div>
          &nbsp;-&nbsp;
          <div class="smallBtn" @click="next">{{ this.chosenLang === 'bn' ? `次` : `Next` }}</div>
        </div>
      </div>

      <table>
        <thead>
          <tr>
            <th>{{ this.chosenLang === 'bn' ? `日付` : `Date` }}</th>
            <th>{{ this.chosenLang === 'bn' ? `最初` : `First` }}</th>
            <th class="desktopOnly">{{ this.chosenLang === 'bn' ? `2番目` : `Second` }}</th>
            <th class="desktopOnly">{{ this.chosenLang === 'bn' ? `第3` : `Third` }}</th>
            <th class="desktopOnly">{{ this.chosenLang === 'bn' ? `抽選番号` : `Draw No` }}</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(result, index) in results" :key="index">
            <td>{{ result.date | dateFormatter }}</td>
            <td>{{ result.first }}</td>
            <td class="desktopOnly">{{ result.second }}</td>
            <td class="desktopOnly">{{ result.third }}</td>
            <td class="desktopOnly">{{ result.drawNo }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <Footer />
  </div>
</template>

<script>
import Navbar from '../components/Navbar';
import RunningText from '../components/RunningText';
import Footer from '../components/Footer';
import moment from 'moment';

export default {
  components: {
    Navbar,
    RunningText,
    Footer,
  },

  mounted() {
    this.getResult(10);
    document.title = 'About | Osaka Lotteries';
  },

  methods: {
    getResult(limit) {
      fetch(`/api/result?limit=${limit}&skip=${this.skip}`)
        .then(response => {
          response.json().then(res => {
            this.results = res;
          });
        })
        .catch(response => {
          response.json().then(res => {
            alert(res.message);
          });
        });
    },

    next() {
      // cek halaman terakhir itu halaman berapa
      fetch(`/api/page`)
        .then(response => {
          response.json().then(res => {
            // selama belum halaman terakhir lanjut terus
            if (this.page + 1 !== res) {
              this.page++;
              this.getResult(10);
            }
          });
        })
        .catch(response => {
          response.json().then(res => {
            alert(res.message);
          });
        });
    },

    prev() {
      // kalau ini halaman pertama langsung return;
      if (this.page == 0) return;
      this.page--;
      this.getResult(10);
    },
  },

  data() {
    return {
      results: [],
      page: 0,
    };
  },

  filters: {
    dateFormatter(date) {
      if (!localStorage.getItem('chosenLang') || localStorage.getItem('chosenLang') === 'bn') {
        moment.locale('ja');
      } else {
        moment.locale('en');
      }

      return moment(date).format('DD MMMM YYYY');
    },
  },
  computed: {
    skip() {
      return this.page * 10;
    },
  },
};
</script>

<style scoped>
#ResultContent {
  background-color: white;
  width: 80%;
  max-width: 1152px;
  margin: auto;
  margin-top: 30px;
  padding: 30px;
  box-sizing: border-box;
  border-radius: 5px;
}

#ResultHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: dashed 2px var(--light);
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.smallBtn {
  margin: 0 3px;
  cursor: pointer;
}

.smallBtn:hover {
  text-decoration: underline;
}

#smallBtnWrap {
  display: flex;
  user-select: none;
}

#ResultContent tbody tr td {
  background-color: var(--light);
}

#ResultContent table {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 10px;
}

@media all and (max-width: 768px) {
  #ResultContent {
    width: 90%;
    margin-top: 20px;
    padding: 0;
    background-color: unset;
  }

  #ResultHead {
    color: white;
  }
}
</style>
