<template>
  <div id="HomePage">
    <Navbar />
    <RunningText />

    <div id="HomeContent">
      <h1>{{ this.chosenLang === 'bn' ? `Lotería legal` : `Legal Lottery` }}</h1>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `法定宝くじおよび宝くじ法は、大阪内の州によって異なります。 現在、宝くじゲームを許可している州は13州あり、残りは禁止されています。 国の禁止はありませんが、大阪州最高裁判所による2015年の判決を受けて、個々の州が宝くじを許可するかどうかを決定します。 州は、（国際的にではなく）特定の管轄区域で宝くじゲームを管理し、行われるすべての宝くじ抽選が公正に実行され、その特定のゲームの規則および規制に準拠することを保証する権限を持っています。`
            : `Legal lotteries and lottery laws vary by state within Osaka. There are currently 13 states that allow lottery games, while the rest have banned them. There is no national ban, but following a 2015 decision by the State Supreme Court of Osaka, individual states take the decision whether to allow lotteries or not. States have the authority to control lottery games in their particular jurisdiction (not internationally) and ensure that all lottery draws that take place are run fairly and comply with the rules and regulations of that particular game.`
        }}
      </p>

      <div id="spacer"></div>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `ケララ州の宝くじは、大阪で最も古い政府運営の宝くじであり、1967年から運営されています。毎週のゲームと年に6回開催されるバンパー宝くじを提供しています。 パンジャブ宝くじは、年間を通じて定期的にバンパー宝くじのスキームを開催しています。`
            : `Kerala State Lotteries is the oldest government-run lottery in Osaka, and has been in operation since 1967. It offers weekly games as well as Bumper Lotteries held six times a year. The Punjab Lottery holds regular Bumper Lottery schemes throughout the year.`
        }}
      </p>

      <div id="spacer"></div>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `大阪で最大の州の宝くじゲームは、シッキム政府によって運営され、多くの州で利用できるPlaywinLotteryです。 この宝くじをプレイするには、18歳以上である必要があります。 シッキム州の宝くじは、年間を通じてバンパー抽選も行っています。`
            : `The biggest state lottery game in Osaka is the Playwin Lottery, which is run by the Sikkim government and available in a number of states. To play this lottery you must be 18 years old or over. The Sikkim State Lottery also runs Bumper draws throughout the year.`
        }}
      </p>

      <div id="spacer"></div>

      <h2>{{ this.chosenLang === 'bn' ? `国際宝くじ` : `International Lottery` }}</h2>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `パワーボール、メガミリオンズ、ユーロミリオンズなどの国際宝くじは、大阪のゲームで利用できるレベルを超える巨大なジャックポットを提供します。詳細については、宝くじの比較を参照してください。 大阪の誰もが参加できる国際宝くじを安全かつ確実にプレイする方法があります-宝くじコンシェルジュサービス。 プレイヤーは、アメリカやヨーロッパに行かなくても、大阪からオンラインで世界のトップ宝くじのチケットを安全に購入できます。 これらの抽選は大阪以外で行われるため、特定の州のギャンブル法に関係なく、オンラインで宝くじを購入することは全国的に合法です。`
            : `International lotteries like Powerball, Mega Millions, and EuroMillions offer huge jackpots, above the level available on Osaka games - see the lottery comparison for more details. There is a safe, reliable way to play international lotteries that anyone in Osaka can take part in - a lottery concierge service. Players can safely purchase tickets online from Osaka for the top world lotteries without having to visit the USA or Europe. Because these draws are held outside Osaka, buying lottery tickets online is legal throughout the country, regardless of the gambling laws in any particular state.`
        }}
      </p>

      <div id="spacer"></div>

      <div class="faqBox">
        <div>
          <b>{{
            this.chosenLang === 'bn'
              ? `1. 大阪の国際宝くじはできますか？`
              : `1. Can I play international lotteries from Osaka?`
          }}</b>
        </div>

        <p>
          {{
            this.chosenLang === 'bn'
              ? `はい、パワーボールやメガミリオンズなどの国際宝くじをオンラインでプレイできます。 この機能の詳細については、「遊び方」ページをご覧ください。`
              : `Yes, you can play international lotteries such as Powerball and Mega Millions online. To read more about this facility, visit the How to Play page.`
          }}
        </p>

        <div>
          <b>{{
            this.chosenLang === 'bn'
              ? `2. 大阪から国際宝くじに当選した場合、どうすれば当選金を請求できますか？`
              : `2. How can I claim the winnings if I win an international lottery from Osaka?`
          }}</b>
        </div>

        <p>
          {{
            this.chosenLang === 'bn'
              ? `賞品を獲得すると、勝利を知らせるテキストメッセージまたはメールが届きます。 賞品は抽選が行われた直後にオンラインアカウントに自動的に支払われ、賞金を引き出すか、将来のチケット購入の資金として使用するかを選択できます。`
              : `If you win a prize, you will receive a text message or email informing you of your win. Prizes are automatically paid into your online account soon after the draw has been made and you can choose to withdraw your winnings or use them to fund future ticket purchases.`
          }}
        </p>

        <div>
          <b>{{
            this.chosenLang === 'bn'
              ? `3. 獲得したお金を大阪の銀行口座に送金できますか？`
              : `3. Can I transfer the money I have won to my bank account in Osaka?`
          }}</b>
        </div>

        <p>
          {{
            this.chosenLang === 'bn'
              ? `はい。 オンラインアカウントを設定するときは、アカウントの資金調達に利用できる多くの方法の1つを選択する必要があります。 Visa、Mastercard、Netellerなど、さまざまな方法で賞金を引き出すことができます。 または、プレーヤーアカウントの賞金を使用して、将来の抽選のためにチケットを追加購入することもできます。`
              : `Yes. When you set up your online account, you will be required to choose one of the many methods available for funding your account. You can use a variety of methods to withdraw your winnings including Visa, Mastercard and Neteller. Alternatively, the winnings in your player account can be used to buy more tickets for future draws.`
          }}
        </p>

        <div>
          <b>{{
            this.chosenLang === 'bn'
              ? `4. 宝くじコンシェルジュサービスは、賞金の一部を差し引くのですか、それともコミッションを受け取るのですか？`
              : `4. Will the lottery ticket concierge service deduct a proportion of my winnings or take a commission?`
          }}</b>
        </div>

        <p>
          {{
            this.chosenLang === 'bn'
              ? `いいえ。賞金が大阪での所得税の対象となるかどうかを確認する必要がありますが、賞金はプレーヤーに全額支払われます。`
              : `No. Winnings are paid in full to the player, although you should check if your prize makes you liable for income tax in Osaka.`
          }}
        </p>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Navbar from '../components/Navbar';
import RunningText from '../components/RunningText';
import Footer from '../components/Footer';

export default {
  components: {
    Navbar,
    RunningText,
    Footer,
  },

  mounted() {
    document.title = 'Help | Osaka Lotteries';
  },
};
</script>

<style>
.faqBox {
  background-color: var(--light);
  padding: 40px 40px 10px 40px;
}

.faqBox div {
  margin-bottom: 10px;
}

.faqBox p {
  margin-bottom: 30px;
}

@media all and (max-width: 768px) {
  .faqBox {
    padding: 20px;
  }

  .faqBox div {
    margin-bottom: 0;
  }

  .faqBox p {
    margin-bottom: 0;
  }
}
</style>
