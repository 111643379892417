<template>
  <div>
    <!-- Navbar ini untuk Desktop -->
    <div id="Navbar">
      <div id="NavLeft">
        <div id="LogoContainer">
          <router-link to="/" class="menuItem">
            <img src="../assets/logo.png" alt="Osaka Lotteries Logo" />
          </router-link>
        </div>

        <div id="MenuContainer">
          <router-link to="/" class="menuItem">{{ this.chosenLang === 'bn' ? `ホーム` : `Home` }}</router-link>

          <router-link to="/how-to-play" class="menuItem">{{
            this.chosenLang === 'bn' ? `遊び方` : `How to Play`
          }}</router-link>

          <router-link to="/help" class="menuItem">{{ this.chosenLang === 'bn' ? `助けて` : `Help` }}</router-link>
          <router-link to="/results" class="menuItem">{{ this.chosenLang === 'bn' ? `結果` : `Results` }}</router-link>
        </div>
      </div>

      <!-- Ini untuk pilih bahasa -->
      <div id="NavRight">
        <select name="language" v-model="langSelected" id="NavbarSelect" @change="changeLang($event)">
          <option value="bn">日本語</option>
          <option value="en">English</option>
        </select>
      </div>
    </div>

    <!-- Navbar on mobile version -->
    <div id="NavMobile">
      <!-- Logo -->
      <router-link to="/" class="logoMob">
        <img src="../assets/logo.png" alt="Osaka Lotteries Logo" />
      </router-link>

      <!-- Hamburger menu -->
      <div class="hamburger" @click="showMenu">
        <img src="../assets/hamburger.png" alt="Hamburger Menu Icon" />
      </div>

      <!-- Dropdown menu content -->
      <transition enter-active-class="animated fadeInRight faster" leave-active-class="animated fadeOutRight faster">
        <div class="menuWrap" v-if="menuShow">
          <router-link to="/" class="menuItem">{{ this.chosenLang === 'bn' ? `ホーム` : `Home` }}</router-link>

          <router-link to="/how-to-play" class="menuItem">{{
            this.chosenLang === 'bn' ? `遊び方` : `How to Play`
          }}</router-link>

          <router-link to="/help" class="menuItem">{{ this.chosenLang === 'bn' ? `助けて` : `Help` }}</router-link>
          <router-link to="/results" class="menuItem">{{ this.chosenLang === 'bn' ? `結果` : `Results` }}</router-link>

          <select name="language" v-model="langSelected" id="NavbarSelect" @change="changeLang($event)">
            <option value="bn">日本語</option>
            <option value="en">English</option>
          </select>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { EventBus } from '../main';

export default {
  data() {
    return {
      langSelected: '',
      menuShow: false,
    };
  },

  methods: {
    changeLang(ev) {
      localStorage.setItem('chosenLang', ev.target.value);
      EventBus.$emit('set-lang');
    },

    showMenu() {
      this.menuShow = !this.menuShow;
    },
  },

  mounted() {
    if (!localStorage.getItem('chosenLang') || localStorage.getItem('chosenLang') === 'bn') {
      this.langSelected = 'bn';
    } else {
      this.langSelected = 'en';
    }
  },
};
</script>

<style>
#Navbar {
  background: linear-gradient(#ddbde0, var(--primary));
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#NavLeft {
  display: flex;
  align-items: center;
}

#MenuContainer {
  display: flex;
  align-items: center;
}

#LogoContainer {
  background-color: white;
  padding: 15px 50px;
}

#LogoContainer img {
  width: 150px;
}

#NavbarSelect {
  border-color: var(--light);
  color: var(--light);
}

#NavRight {
  margin-right: 20px;
}

.menuItem {
  font-weight: bold;
  color: var(--light);
  padding: 15px 25px;
}

.menuItem:hover {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
}

.menuItem:active {
  background-color: unset;
  color: var(--dark);
}

#MenuContainer {
  margin-left: 20px;
}

#NavMobile {
  display: none;
}

@media all and (max-width: 768px) {
  #Navbar {
    display: none;
  }

  #NavMobile {
    display: flex;
    height: 80px;
  }

  .logoMob {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: white;
    flex-grow: 1;
  }

  .logoMob img {
    width: 100%;
  }

  .hamburger {
    background-color: var(--primary);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px;
  }

  .hamburger img {
    width: 40px;
  }

  .menuWrap {
    position: absolute;
    top: 80px;
    right: 0;
    background-color: white;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
  }

  .menuWrap .menuItem {
    color: var(--dark);
    display: flex;
  }

  #HomeContent h2 {
    padding: 10px;
  }

  .faqBox {
    padding: 20px;
    padding-top: 40px;
  }

  .faqBox b {
    line-height: 125%;
  }

  #NavbarSelect {
    border-color: black;
    color: black;
    margin: 5px 25px 20px 25px;
  }
}
</style>
