<template>
  <div id="HomePage">
    <Navbar />
    <RunningText />

    <div id="HomeContent">
      <h1>{{ this.chosenLang === 'bn' ? `遊び方` : `How To Play` }}</h1>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `パワーボール、メガミリオンズ、ユーロミリオンズのような大きな国際宝くじをプレイしたいと思ったことはありませんか？ 今、あなたは大阪からこれらの素晴らしいゲームをオンラインでプレイすることができます。 アメリカの宝くじは大阪のギャンブル規制の対象ではないので、コンピューターや携帯電話で全国どこからでもプレイできます。 メキシコの宝くじの最優秀賞よりもはるかに大きいジャックポットを獲得するチャンスがあります。`
            : `Ever wished you could play a big international lottery like Powerball, Mega Millions, and EuroMillions? Now you can play these incredible games online from Osaka. American lotteries are not subject to Osaka gambling restrictions, so you can play from anywhere in the country on your computer or phone. You'll have the chance to win jackpots that are much larger than the Mexican lottery top prizes.`
        }}
      </p>

      <div id="spacer"></div>
      <img src="../assets/how-to-play.jpg" alt="How To Play" class="desktopOnly" />
      <div id="spacer" class="desktopOnly"></div>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `オンラインで宝くじをするのは簡単なので、オンラインでチケットを購入したことがなくても、宝くじのコンシェルジュサービスを利用したことがなくても心配はいりません。 開始するには、次の手順に従ってください。`
            : `Playing the lottery online is simple, so don't worry if you haven't bought a ticket online or used a lottery concierge service before. Just follow these steps to get started:`
        }}
      </p>

      <div id="spacer"></div>

      <div class="faqBox">
        <ul>
          <li>
            {{
              this.chosenLang === 'bn'
                ? `osakalotteries.comのバナーまたは[今すぐ再生]ボタンのいずれかをクリックします。`
                : `Click on any of the banners or Play Now buttons on osakalotteries.com.`
            }}
          </li>
          <li>
            {{
              this.chosenLang === 'bn' ? `プレイしたい宝くじを選択してください` : `Choose the lottery you wish to play`
            }}
          </li>
          <li>
            {{
              this.chosenLang === 'bn'
                ? `番号セレクタグリッドを使用して手動で番号を選択するか、[クイックピック]ボタンを使用してランダムな番号のセットを生成します。`
                : `Select your numbers manually by using the number selector grid or generate a random set of numbers using the Quick Pick button.`
            }}
          </li>
          <li>
            {{
              this.chosenLang === 'bn'
                ? `[続行]ボタンをクリックして、購入を確認します。`
                : `Click the Continue button to confirm your purchase.`
            }}
          </li>
          <li>
            {{
              this.chosenLang === 'bn'
                ? `詳細を入力してアカウントを開設するか、新しいアカウントを登録して購入を完了してください。`
                : `Enter your details to open your account, or register a new account, and complete your purchase.`
            }}
          </li>
          <li>
            {{
              this.chosenLang === 'bn'
                ? `ご注文を受け取った後、オンラインアカウントで番号を確認できます。`
                : `After receiving your order, you can view your numbers in your online account.`
            }}
          </li>
        </ul>
      </div>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `賞品を獲得すると、アカウント登録時に指定したメールアドレスにテキスト/ SMSまたはメールが届きます。 また、各抽選が行われた直後に、宝くじのページで各宝くじの結果を表示することもできます。`
            : `If you win a prize, you'll receive a text/SMS or email to the email address provided when you registered your account. You can also view the results for each lottery on the lottery pages shortly after each draw has taken place.`
        }}
      </p>

      <div id="spacer"></div>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `賞金はオンラインアカウントに直接支払われます。 賞品は、引き出したり、将来のチケット購入に使用したりできます。`
            : `Winnings are paid directly into your online account. The prize winnings can then be withdrawn or used for future ticket purchases.`
        }}
      </p>

      <div id="spacer"></div>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `フロリダロトで3000万ドルを獲得したパナマの女性と、640万ドルのオレゴンメガバックスジャックポットを獲得したイラク人の男性の話をチェックして、自分で国際宝くじの賞金を獲得するのがいかに簡単かを調べてください。`
            : `Check out the stories of a woman from Panama who won $30 million on Florida Lotto, and an Iraqi man who won an Oregon Megabucks jackpot of $6.4 million, to find out how easy it is to win an international lottery prize for yourself.`
        }}
      </p>
    </div>

    <Footer />
  </div>
</template>

<script>
import Navbar from '../components/Navbar';
import RunningText from '../components/RunningText';
import Footer from '../components/Footer';

export default {
  components: {
    Navbar,
    RunningText,
    Footer,
  },

  mounted() {
    document.title = 'How To Play | Osaka Lotteries';
  },
};
</script>

<style>
.faqBox {
  margin-bottom: 20px;
}

@media all and (max-width: 768px) {
  .faqBox {
    padding: 20px;
  }

  .faqBox div {
    margin-bottom: 0;
  }

  .faqBox p {
    margin-bottom: 0;
  }
}
</style>
