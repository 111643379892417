<template>
  <div id="HomePage">
    <Navbar />
    <RunningText />

    <div id="HomeContent">
      <h1>{{ this.chosenLang === 'bn' ? `お問い合わせ` : `Contact Us` }}</h1>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `大阪の宝くじ、結果、賞品など、ご不明な点がございましたら、当チームがすべてのご質問にお答えします。 以下のアドレスにメールを送ってください。`
            : `Whether you have a question about Osaka Lotteries, results, prizes, or anything else, our team is here ready to answer all of your questions. Please send an email to us at the address below:`
        }}
      </p>

      <div id="spacer"></div>

      <div class="faqBox">
        <b>support@osakalotteries.com</b>
        <div id="spacer" class="desktopOnly"></div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Navbar from '../components/Navbar';
import RunningText from '../components/RunningText';
import Footer from '../components/Footer';

export default {
  components: {
    Navbar,
    RunningText,
    Footer,
  },

  mounted() {
    document.title = 'Contact Us | Osaka Lotteries';
  },
};
</script>

<style>
@media all and (max-width: 768px) {
  .faqBox {
    padding: 20px;
  }

  .faqBox div {
    margin-bottom: 0;
  }

  .faqBox p {
    margin-bottom: 0;
  }
}
</style>
