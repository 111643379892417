<template>
  <div id="HomePage">
    <Navbar />
    <RunningText />

    <div id="HomeContent">
      <h1>{{ this.chosenLang === 'bn' ? `私たちに関しては` : `About Us` }}</h1>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `大阪宝くじの世界に信頼性をもたらす`
            : `Bringing reliability to the world of Osaka Lotteries`
        }}
      </p>

      <div id="spacer"></div>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `大阪宝くじは、プロの作家、宝くじの専門家、地元の大阪の才能からなる小さな献身的なチームによってもたらされます。 チームはすべて大阪に住んでいたので、ニュース、虚偽のニュース、噂、伝聞を取り巻く混乱を認識しました。 これは、オンラインと路上での宝くじの無計画なセクターほど明白ではありません。`
            : `Osaka Lotteries is brought to you by a small, dedicated team of professional writers, lottery experts and local Osaka talent. Having all spent time living in Osaka, The Team recognised the chaos that surrounds news, false news, rumour and hearsay. This is no more evident than in the haphazard sector of lotteries, both online and in the street.`
        }}
      </p>

      <div id="spacer"></div>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `大阪宝くじは毎日出入りしているようで、抽選後1週間程度まで当選番号は発表されず、信頼できる宝くじの結果を広めるための信頼できる情報源はありませんでした。 詐欺が蔓延している`
            : `Osaka Lotteries seem to come and go on a daily basis, winning numbers aren't announced until a week or so after the drawing and no reliable source existed for the dissemination of reliable lottery results. Scams are rife.`
        }}
      </p>

      <div id="spacer"></div>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `そして、大阪宝くじは「大阪宝くじの世界に信頼をもたらす」という使命を持って誕生しました。これは、正しい当選番号と結果をタイムリーに報告するために人々が信頼できるウェブサイトです。`
            : `And so Osaka Lotteries was born with the mission to "Bring reliability to the World of Osaka Lotteries" - a website that people could trust to report the correct winning numbers and results in a timely manner.`
        }}
      </p>
    </div>

    <Footer />
  </div>
</template>

<script>
import Navbar from '../components/Navbar';
import RunningText from '../components/RunningText';
import Footer from '../components/Footer';

export default {
  components: {
    Navbar,
    RunningText,
    Footer,
  },

  mounted() {
    document.title = 'About | Osaka Lotteries';
  },
};
</script>

<style>
.faqBox {
  margin-bottom: 20px;
}

@media all and (max-width: 768px) {
  .faqBox {
    padding: 20px;
  }

  .faqBox div {
    margin-bottom: 0;
  }

  .faqBox p {
    margin-bottom: 0;
  }
}
</style>
