<template>
  <div id="HomePage">
    <Navbar />
    <RunningText />

    <div id="HomeContent">
      <h1>{{ this.chosenLang === 'bn' ? `私たちに関しては` : `About Us` }}</h1>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `あなたのプライバシーは大阪宝くじにとって非常に重要です。 当社および当サイトでは、大阪宝くじにご提供いただいた個人情報を安全に収集・保管し、当ウェブサイトの運営に必要な場合に限り利用するよう、厳格な方針を定めております。`
            : `Your privacy is extremely important to Osaka Lotteries. The company and site have strict policies in place to ensure that any personal information you submit to Osaka Lotteries is collected and stored securely and is used only as reasonably necessary for the operation of the website.`
        }}
      </p>

      <div id="spacer"></div>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `このページでは、サイトを閲覧する際に収集するデータの種類、大阪宝くじの使用方法、および安全性の確保方法について説明します。`
            : `This page sets out what type of data we collect when you browse the site, how Osaka Lotteries use it and how it is kept secure.`
        }}
      </p>

      <div id="spacer"></div>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `そして、大阪宝くじは「大阪宝くじの世界に信頼をもたらす」という使命を持って誕生しました。これは、正しい当選番号と結果をタイムリーに報告するために人々が信頼できるウェブサイトです。`
            : `And so Osaka Lotteries was born with the mission to "Bring reliability to the World of Osaka Lotto" - a website that people could trust to report the correct winning numbers and results in a timely manner.`
        }}
      </p>

      <div id="spacer"></div>
      <h2>{{ this.chosenLang === 'bn' ? `どのようなデータを収集しますか？` : `What data do we collect?` }}</h2>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `このウェブサイトを使用すると、次の種類の情報が収集される場合があります。`
            : `We may collect the following kinds of information when you use this website:`
        }}
      </p>

      <div id="spacer"></div>

      <ul>
        <li>
          {{
            this.chosenLang === 'bn'
              ? `このWebサイトの使用方法およびWebサイトの閲覧に使用するデバイスに関する情報。 これには、ブラウザの種類とバージョン、オペレーティングシステム、アクセスしたページ、アクセスの長さ、IPアドレスが含まれますが、これらに限定されません。`
              : `Information about how you use this website and the device you use to browse it. This includes, but is not limited to, your browser type and version, operating system, which pages you visited, the length of your visit and your IP address.`
          }}
        </li>

        <li>
          {{
            this.chosenLang === 'bn'
              ? `目的を問わず、お客様が当社に送信することを選択したその他の情報。`
              : `Any other information you choose to send to us, for any purposes.`
          }}
        </li>
      </ul>

      <h2>{{ this.chosenLang === 'bn' ? `お客様のデータの使用方法` : `How we use your data` }}</h2>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `このウェブサイトを通じて大阪宝くじに送信された個人情報は、以下の目的で使用される場合があります。`
            : `Personal information submitted to Osaka Lotteries via this website may be used for the purposes described below:`
        }}
      </p>

      <div id="spacer"></div>

      <ul>
        <li>{{ this.chosenlang === 'bn' ? `ウェブサイトの管理` : `Website administration` }}</li>

        <li>
          {{
            this.chosenLang === 'bn'
              ? `あなたの興味に応じてウェブサイトを改善し、パーソナライズするため`
              : `To improve and personalise the website according to your interests`
          }}
        </li>

        <li>
          {{
            this.chosenLang === 'bn'
              ? `一般的なコミュニケーションまたはマーケティングコミュニケーション、および電子メール通知を送信するため。ただし、それらの受信を選択した場合のみ。`
              : `To send you general or marketing communications, as well as email notifications but onlyif you have opted in to receive them`
          }}
        </li>

        <li>
          {{
            this.chosenLang === 'bn'
              ? `当社の事業または厳選された第三者の事業に関連するマーケティングコミュニケーションを送信するため。 この情報を受け取ることに特に同意した場合にのみ、この情報を送信します。 お問い合わせいただくことで、いつでもオプトアウトできます`
              : `To send you marketing communications relating to our business or the businesses of carefully selected third-parties. We will only send this information if you have specifically agreed to receive it. You can opt out at any time by contacting us`
          }}
        </li>

        <li>
          {{
            this.chosenLang === 'bn'
              ? `あなたがこのウェブサイトに送った質問や苦情を処理するため`
              : `To handle queries or complaints sent by you to this website`
          }}
        </li>

        <li>
          {{
            this.chosenLang === 'bn'
              ? `ウェブサイトのセキュリティを維持し、詐欺を防ぐために`
              : `To maintain website security and help prevent fraud`
          }}
        </li>

        <li>
          {{
            this.chosenLang === 'bn'
              ? `ウェブサイトサービスの利用を可能にするため`
              : `To enable the use of website services`
          }}
        </li>

        <li>
          {{
            this.chosenLang === 'bn'
              ? `このウェブサイトの条件を確実に順守するため。`
              : `To ensure you are abiding by the terms of this website.`
          }}
        </li>
      </ul>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `大阪宝くじは、お客様がサービスにオプトインした場合にのみ、ダイレクトマーケティングの目的でお客様の情報を第三者と共有します。 いつでも退会できます。`
            : `Osaka Lotteries will only share your information with third parties for direct marketing purposes if you have opted in to the service. You can unsubscribe at any time.`
        }}
      </p>

      <div id="spacer"></div>

      <h2>
        {{ this.chosenLang === 'bn' ? `個人情報の開示` : `Disclosing your personal information` }}
      </h2>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `大阪宝くじは、このプライバシーポリシーに定められた目的のために、必要に応じて、当社の従業員、代理店、サプライヤー、または下請け業者にお客様の個人情報を開示する場合があります。 また、このプライバシーポリシーに記載されている目的のために、必要に応じて、お客様の個人情報を当社グループのメンバー（親会社、持株会社、および子会社を含む）に開示する場合があります。`
            : `Osaka Lotteries may disclose your personal information to any of our employees, agents, suppliers, or subcontractors as necessary for the purposes set out in this privacy policy. We may also disclose your personal information to any member of our group of companies (including parent companies, our holding company and any subsidiaries) as necessary for the purposes set out in this privacy policy.`
        }}
      </p>

      <div id="spacer"></div>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `さらに、お客様の個人情報を開示する場合があります。`
            : `Furthermore, we may disclose your personal information:`
        }}
      </p>

      <div id="spacer"></div>

      <ul>
        <li>{{ this.chosenLang === 'bn' ? `法律で義務付けられている場合` : `If required by law` }}</li>

        <li>
          {{
            this.chosenLang === 'bn'
              ? `詐欺防止の目的を含め、当社の法的権利を確立、行使、または擁護するため`
              : `To establish, exercise or defend our legal rights, including for fraud prevention purposes`
          }}
        </li>

        <li>
          {{
            this.chosenLang === 'bn'
              ? `当社が販売している（または検討している）事業または資産の購入者または購入予定者へ`
              : `To purchasers or prospective purchasers of any business or asset we are (or are considering) selling`
          }}
        </li>

        <li>
          {{
            this.chosenLang === 'bn'
              ? `将来または進行中の法的手続きに関連して`
              : `In connection with any legal proceedings, whether prospective or ongoing`
          }}
        </li>

        <li>
          {{
            this.chosenLang === 'bn'
              ? `当社が合理的に信じる者には、その個人情報の開示を裁判所または管轄当局に申請することができ、当社の合理的な見解では、その当局が当社にその情報の開示を命じる可能性があります。`
              : `To any person who we reasonably believe may apply to a court or competent authority for the disclosure of that personal information where, in our reasonable opinion, that authority would be likely to order us to disclose that information`
          }}
        </li>
      </ul>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `大阪宝くじは、このプライバシーポリシーに記載されている場合を除き、お客様の個人情報を第三者に開示することはありません。`
            : `Osaka Lotteries will not disclose your personal information to third parties, with the exception of those instances described in this privacy policy.`
        }}
      </p>

      <div id="spacer"></div>

      <h2>
        {{ this.chosenLang === 'bn' ? `あなたの個人情報のセキュリティ` : `Security of your personal information` }}
      </h2>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `データセキュリティはosakalotteries.comにとって非常に重要であり、私たちはあなたの個人情報の損失、誤用または改ざんを防ぐためにあらゆる合理的な措置を講じます。 osakalotteries.comに提供する個人データはすべて、安全なサーバーに保存され、このWebサイトを通じて完了するすべての電子取引は暗号化されます。`
            : `Data security is very important to osakalotteries.com and we take all reasonable steps to prevent the loss, misuse or alteration of your personal information. Any personal data you provide to osakalotteries.com is stored on our secure servers and all electronic transactions completed through this website are encrypted.`
        }}
      </p>

      <div id="spacer"></div>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `このウェブサイトを使用し、これらの条件に同意することにより、インターネットを介した情報の送信は潜在的に安全ではなく、osakalotteries.comはインターネットを介して送信されるデータのセキュリティを保証できないことを認めます。`
            : `By using this website and accepting these terms, you acknowledge that the transmission of information over the internet is potentially insecure and that osakalotteries.com cannot guarantee the security of data sent over the internet.`
        }}
      </p>

      <div id="spacer"></div>

      <h2>
        {{ this.chosenLang === 'bn' ? `国際的なデータ転送` : `International data transfers` }}
      </h2>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `このプライバシーポリシーに記載されているように収集した個人情報は、このプライバシーポリシーに記載されている条件に従って使用できるように、当社が事業を行う国のいずれかで保存、処理、または転送される場合があります。`
            : `The personal information that we collect as described in this privacy policy may be stored, processed in or transferred between any of the countries in which we operate so that we can use it in accordance with the terms set out in this privacy policy.`
        }}
      </p>

      <div id="spacer"></div>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `このWebサイトを介して提供する個人情報は、欧州経済領域で施行されているものと同等のデータ保護法がない国に転送され、保存される場合があります。`
            : `Any personal information that you provide via this website may be transferred to and stored in countries which do not have data protection laws equivalent to those in force in the European Economic Area.`
        }}
      </p>

      <div id="spacer"></div>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `このプライバシーポリシーに同意することにより、このウェブサイトを介して公開するために大阪宝くじに提出した個人情報は、その後インターネットおよび世界中で閲覧できるようになる可能性があることを認めるものとします。 大阪宝くじは、他人によるそのような情報の使用または誤用を防ぐことはできません。`
            : `By agreeing to this privacy policy, you acknowledge that any personal information you submit to Osaka Lotteries for publication via this website may subsequently be available to view on the internet and around the world. Osaka Lotteries cannot prevent the use or misuse of such information by others.`
        }}
      </p>

      <div id="spacer"></div>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `このプライバシーポリシーの条件に同意することにより、上記のように個人データを転送することに明示的に同意したことになります。`
            : `By accepting the terms of this privacy policy, you expressly agree to the transfer of your personal data as described above.`
        }}
      </p>

      <div id="spacer"></div>

      <h2>{{ this.chosenLang === 'bn' ? `このポリシーの修正` : `Amending this policy` }}</h2>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `このプライバシーポリシーは、予告なしに更新される場合があります。 ポリシーの新しいバージョンがこのWebサイトで公開されます。変更に対応するために、このページに再度アクセスすることをお勧めします。 このプライバシーポリシーの変更については、メールで通知する場合があります。`
            : `This privacy policy may be updated without notice. New versions of the policy will be published on this website and we advise that you revisit this page to keep up with any changes. We may on occasion notify you of any changes to this privacy policy via email.`
        }}
      </p>

      <div id="spacer"></div>
      <h2>{{ this.chosenLang === 'bn' ? `あなたの権利` : `Your rights` }}</h2>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `あなたは私たちがあなたについて保持しているデータをいつでもあなたに提供するように私たちに指示することができます。 このような要求は、次の条件に従って満たされます。`
            : `You can instruct us to provide you with any data we hold about you at any time. Such requests will be fulfilled subject to the following conditions:`
        }}
      </p>

      <div id="spacer"></div>

      <ul>
        <li>
          {{ this.chosenLang === 'bn' ? `管理費の支払い` : `The payment of an administration fee` }}
        </li>

        <li>
          {{
            this.chosenLang === 'bn'
              ? `あなたの身元の適切な証拠の提供。 これには、パスポートの公証されたコピーと、名前と住所が記載された公共料金の原本が含まれます。 大阪宝くじは、必要に応じてあなたの身元の追加の証明を要求する権利を留保します`
              : `The supply of appropriate evidence of your identity. This includes a notarised copy of your passport and an original copy of a utility bill featuring your name and address. Osaka Lotteries reserve the right to request additional proof of your identity if necessary`
          }}
        </li>
      </ul>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `大阪宝くじは、ユーザーの身元を保護し、個人情報を受け取る権利を有する者にのみ開示するために、これらの文書を必要とします。`
            : `Osaka Lotteries require these documents to protect the identities of our users and to ensure personal information is only disclosed to the person who has the right to receive it.`
        }}
      </p>

      <div id="spacer"></div>
      <h2>{{ this.chosenLang === 'bn' ? `サードパーティのWebサイト` : `Third-party websites` }}</h2>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `大阪宝くじには、他のウェブサイトへのリンクが含まれている場合があります。 当社は、リンク先のサードパーティサイトのコンテンツまたは運用慣行を保証せず、責任を負いません。`
            : `Osaka Lotteries may contain links to other websites. We do not endorse and are not responsible for the content or operating practices of the third-party sites we link to.`
        }}
      </p>

      <div id="spacer"></div>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `このページに記載されているプライバシーポリシーは、大阪の宝くじにのみ関係し、独自のプライバシーポリシーが適用されるサードパーティのウェブサイトには適用されません。 サードパーティのWebサイトがお客様のデータを収集または使用する方法について責任を負わないため、サードパーティのWebサイトのプライバシーポリシーをお読みになることをお勧めします。`
            : `The privacy policy set out on this page only pertains to Osaka Lotteries and doesn’t cover any third-party websites, which will have their own privacy policies in place. We advise that you read the privacy policy of any third-party website as we cannot be held responsible for how they collect or use your data.`
        }}
      </p>

      <div id="spacer"></div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Navbar from '../components/Navbar';
import RunningText from '../components/RunningText';
import Footer from '../components/Footer';

export default {
  components: {
    Navbar,
    RunningText,
    Footer,
  },

  mounted() {
    document.title = 'Privacy Policy | Osaka Lotteries';
  },
};
</script>

<style>
.faqBox {
  margin-bottom: 20px;
}

@media all and (max-width: 768px) {
  .faqBox {
    padding: 20px;
  }

  .faqBox div {
    margin-bottom: 0;
  }

  .faqBox p {
    margin-bottom: 0;
  }
}
</style>
