<template>
  <div id="JackpotBox">
    <div id="jackpotHead">
      <img src="../assets/jackpot-icon.png" alt="Jackpot Icon" />
      {{ this.chosenLang === 'bn' ? `メガジャックポット` : `Mega Jackpot` }}
    </div>

    <div id="jackpotBody">
      <div id="jackpotBodyLeft">
        <div id="jackpotUSD" class="flash">¥{{ jackpot | thousandSeparator }}</div>

        <div style="display: flex; justify-content: space-between; margin-top: 20px">
          <div id="jackpotBDT">
            {{ this.chosenLang === 'bn' ? `それは周りです` : `That's around` }} ${{
              jackpot | convertToBDT | thousandSeparator
            }}
          </div>

          <div id="smallDate">{{ date | formatDate }}</div>
        </div>
      </div>

      <div id="jackpotBodyRight">
        <div id="rightTop">
          {{
            this.chosenLang === 'bn'
              ? `メガジャックポットは、すべての人に1回だけ発生する賞品です。 それは856万ドルの記録的な大当たりを持っています`
              : `Mega Jackpot is a prize that only happens once for every person. It has a record jackpot of $8.56 million.`
          }}
        </div>

        <div id="rightBottom">
          <div id="hourglass">
            <div>
              <img src="../assets/hourglass-icon.png" alt="Hourglass Icon" />

              <div>
                {{ this.chosenLang === 'bn' ? `次` : `Next` }}
                <br />
                {{ this.chosenLang === 'bn' ? `ドロー` : `Draw` }}
              </div>
            </div>
          </div>

          <div class="counter">
            <div>
              <div class="value">{{ hours }}</div>
              <div class="timeDesc">{{ this.chosenLang === 'bn' ? `時間` : `Hours` }}</div>
            </div>
          </div>

          <div class="counter">
            <div>
              <div class="value">{{ minutes }}</div>
              <div class="timeDesc">{{ this.chosenLang === 'bn' ? `議事録` : `Minutes` }}</div>
            </div>
          </div>

          <div class="counter">
            <div>
              <div class="value">{{ seconds }}</div>
              <div class="timeDesc">{{ this.chosenLang === 'bn' ? `秒` : `Seconds` }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: ['jackpot', 'date'],

  filters: {
    convertToBDT(usd) {
      return Math.floor(usd * 84.8);
    },

    thousandSeparator(numbers) {
      if (numbers === undefined || numbers === '' || numbers === null) {
        return '';
      } else {
        let result = '';
        let counter = 0;

        for (let i = numbers.toString().length; i >= 0; i--) {
          if (counter % 3 === 0 && counter !== 0 && counter !== numbers.toString().length) {
            result = ',' + numbers.toString().substr(i, 1) + result;
          } else {
            result = numbers.toString().substr(i, 1) + result;
          }
          counter += 1;
        }

        return result;
      }
    },

    formatDate(date) {
      if (!localStorage.getItem('chosenLang') || localStorage.getItem('chosenLang') === 'bn') {
        moment.locale('ja');
      } else {
        moment.locale('en');
      }

      return moment(date).format('Do MMMM YYYY');
    },
  },

  mounted() {
    this.initializeTimer();
  },

  destroyed() {
    clearInterval(this.interval);
  },

  methods: {
    initializeTimer() {
      const nextRes = new Date();
      nextRes.setHours(14, 30, 0, 0);

      if (moment(nextRes).diff(moment(new Date()), 'milliseconds') < 0) {
        nextRes.setDate(nextRes.getDate() + 1);
      }

      this.interval = setInterval(() => {
        this.hours = moment(nextRes).diff(moment(new Date()), 'hours');
        this.minutes = moment(nextRes).diff(moment(new Date()), 'minutes') % 60;
        this.seconds = (moment(nextRes).diff(moment(new Date()), 'seconds') % 60) % 60;
      }, 1000);
    },
  },

  data() {
    return {
      hours: '',
      minutes: '',
      seconds: '',
    };
  },
};
</script>

<style>
#jackpotHead {
  background-color: var(--dark-accent);
  color: white;
  width: fit-content;
  padding: 12px 22px;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  position: relative;
  top: 30px;
  margin-left: 20px;
  font-weight: bold;
  font-size: 1.2em;
  display: flex;
  align-items: center;
}

#jackpotHead img {
  width: 40px;
  margin-right: 20px;
}

#jackpotBody {
  display: flex;
  justify-content: space-between;
  background: linear-gradient(white, var(--light));
  border: solid 1px var(--light);
  border-radius: 5px;
}

#jackpotUSD {
  font-size: 4vw;
  font-weight: bold;
  margin-bottom: 10px;
}

#jackpotBDT {
  color: var(--dark-accent);
  font-weight: bold;
}

#smallDate {
  color: var(--dark-accent);
}

#jackpotBodyLeft {
  padding: 60px 22px 32px 50px;
}

#jackpotBodyRight {
  background-color: var(--light-accent);
  border-radius: 0 5px 5px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 28vw;
  max-width: 400px;
}

#rightBottom {
  display: flex;
}

#hourglass {
  background-color: var(--dark-accent);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 15px 0;
  font-weight: bold;
  color: white;
  flex-grow: 1;
}

#hourglass div {
  display: flex;
}

#hourglass img {
  width: 25px;
  margin-right: 10px;
  height: 29px;
}

.counter {
  background-color: white;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  width: 90px;
  font-size: small;
  border-right: solid 1px var(--light);
}

.counter:last-child {
  border-right: none;
}

.timeDesc {
  padding: 2px 0 0 0;
}

.value {
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-grow: 1;
  font-size: 2em;
}

#rightTop {
  color: white;
  line-height: 125%;
  text-align: center;
  padding: 25px 25px 25px 125px;
  box-sizing: border-box;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
  background: url('../assets/person.gif');
  background-repeat: no-repeat;
  background-size: 135px;
}

@media all and (max-width: 768px) {
  #jackpotBody {
    display: block;
  }

  #jackpotBodyLeft {
    padding: 60px 20px 20px 20px;
    overflow: hidden;
  }

  #smallDate {
    text-align: right;
  }

  #jackpotUSD {
    animation-name: running;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    font-size: 20vw;
  }

  @keyframes running {
    0% {
      margin-left: -200vw;
    }
    100% {
      margin-left: 110vw;
    }
  }

  #jackpotBodyRight {
    margin-top: 20px;
    width: unset;
  }

  #rightTop {
    background-position-y: 3vw;
    padding: 15px 15px 15px 120px;
  }

  .counter {
    width: 60px;
  }

  .timeDesc {
    font-size: smaller;
  }

  .value {
    font-size: 1.7em;
  }
}

@media all and (min-width: 1920px) {
  #jackpotUSD {
    font-size: 2.5vw;
  }
}
</style>
